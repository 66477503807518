import { useEffect, useMemo, useState } from 'react';
import { Flex } from '@clariness/flex';
import { Services } from 'services';
import { Table } from 'components/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import { ServiceError } from 'components/ServiceError';
import { Store } from 'store';
import { getRefreshToken } from 'utils/auth/tokens';
import { Icon, Text } from '@clariness/clarikit-react';
import { FilterIcon } from '@clariness/clarikit-icons';
import { Box } from '@clariness/box';
import { logout } from 'utils/auth';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { ReferralTableExportXls, ReferralTableFilter } from './components';
import { mapReferralsToAccessor, referralColumns } from './referralsTableData';

export const MAX_REFERRALS_ROWS = (() => {
  if (window.innerHeight < 761) {
    return 6;
  }

  if (window.innerHeight < 900) {
    return 8;
  }

  return 10;
})();

export const Home = () => {
  const { referrals, fetchReferrals, refetch, isFetching, isError } =
    Services.Referrals.useSearchReferrals({ take: MAX_REFERRALS_ROWS });

  const { openModal } = Store.useModal();

  const {
    filterOptions,
    selectedRows,
    setSelectedRows,
    unselectedRowIds,
    setUnselectedRowIds,
    setSelectedRowIds,
    paginationOptions,
  } = Store.useHomePageStore();

  const { user } = Store.useUser();

  const referralsData = useMemo(
    () => (referrals ? referrals.results.map(mapReferralsToAccessor) : []),
    // make deep comparison
    [JSON.stringify(referrals)]
  );

  const [showFilters, setShowFilters] = useState<boolean>(true);

  const intl = useIntl();

  const desktopWidth = 1366;

  //logout if refresh token is empty
  useEffect(() => {
    if (!getRefreshToken()) {
      logout();
    }
  }, []);

  const recontactEnabled = useFeatureFlagEnabled(
    'REACT_APP_MARK_PATIENT_ENABLED'
  );

  useEffect(() => {
    const isRecontactPatient = referrals?.results.find(
      (referral) => referral.recontactMark === true
    );

    if (
      !sessionStorage.getItem('RECONTACTED_PATIENT_MESSAGE') &&
      isRecontactPatient &&
      recontactEnabled
    ) {
      sessionStorage.setItem('RECONTACTED_PATIENT_MESSAGE', 'true');
      openModal({
        modalType: 'RecontactedPatient',
        modalProps: {
          investigatorID: user?.id || '',
        },
      });
    }
  }, [referrals, openModal, recontactEnabled, user?.id]);

  useEffect(() => {
    setSelectedRows((prev) =>
      prev.filter((row) => filterOptions.statuses?.includes(row.values.status))
    );
  }, [filterOptions]);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        py: '40px',
        px: window?.innerWidth < desktopWidth ? '80px' : '110px',
        marginBottom: '8px',
      }}
    >
      <Flex justifyContent="space-between">
        <Text as="h3" variant="headingMd">
          <FormattedMessage
            id="is.patient_list.title"
            defaultMessage="Patient List"
          />
        </Text>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: `${showFilters && '#CAE7BE'}`,
            display: 'flex',
            alignItems: 'center',
            marginRight: '16px',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setShowFilters(!showFilters)}
        >
          <Icon
            color={showFilters ? 'color-green-primary' : 'color-gray-variant-1'}
            size="small"
            source={FilterIcon}
          />
        </Box>
      </Flex>

      {user?.sites?.length === 1 && (
        <Box marginBottom="28px">
          <Text as="p" variant="bodyMd" fontWeight="bold">
            {user.sites[0].name}
          </Text>
        </Box>
      )}

      <Flex>
        <ReferralTableFilter showFilters={showFilters} />
      </Flex>

      <Table
        columns={referralColumns}
        data={referralsData}
        totalRows={referrals?.total}
        maxRowsPerPage={MAX_REFERRALS_ROWS}
        isLoading={isFetching}
        onPageChange={fetchReferrals}
        onSelect={setSelectedRows}
        selectedRows={selectedRows}
        setUnselectedRows={setUnselectedRowIds}
        unSelectedRows={unselectedRowIds}
        setSelectedRows={setSelectedRowIds}
        currentPageIndex={
          paginationOptions.skip &&
          paginationOptions.take &&
          paginationOptions.skip / paginationOptions.take
        }
      >
        <ShouldRender when={isError}>
          <ServiceError onRetry={refetch}>
            <FormattedMessage
              id="is.main_page.referrals_table.generic_error_message"
              defaultMessage="Something went wrong while we tried to display your referrals."
            />
          </ServiceError>
        </ShouldRender>

        <ShouldRender when={Boolean(referrals && !referrals.results.length)}>
          {filterOptions?.patientName ? (
            <Table.NoResults
              title={intl.formatMessage(
                {
                  id: 'is.main_page.referrals_table.no_matching_patients_title',
                  defaultMessage:
                    'We couldn\'t find any patients matching "{ searchText }".',
                },
                { searchText: filterOptions.patientName }
              )}
              description={intl.formatMessage({
                id: 'is.main_page.referrals_table.no_matching_patients_description',
                defaultMessage:
                  'Please check for typos or try searching something else.',
              })}
            />
          ) : (
            <Table.NoResults
              title={intl.formatMessage({
                id: 'is.main_page.referrals_table.no_referrals_title',
                defaultMessage: "Looks like you don't have any referrals yet.",
              })}
            />
          )}
        </ShouldRender>
      </Table>

      <ReferralTableExportXls />
    </Flex>
  );
};
