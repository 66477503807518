import React from 'react';
import { AccountPageLayout } from 'pages/Account/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { Grid } from '@clariness/grid';
import { PasswordField } from '@clariness/password-field';
import { useMultiStep } from 'components/MultiStep';
import { Schemas, useFormValidation } from 'utils/validation';
import { Services } from 'services';
import { ChangeCurrentPasswordDto } from 'common/api';
import { Events } from '@clariness/tracking-event-runtime';

export const AccountDetailsChangePassword = () => {
  const { nextStep, prevStep } = useMultiStep();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useFormValidation();

  const intl = useIntl();

  const { updatePassword, isLoading } =
    Services.SiteEmployees.useUpdatePassword();

  return (
    <AccountPageLayout>
      <AccountPageLayout.Heading>
        <FormattedMessage
          id="is.account_details.change_password.heading"
          defaultMessage="Change password"
        />
      </AccountPageLayout.Heading>

      <AccountPageLayout.Form
        onSubmit={handleSubmit((data: ChangeCurrentPasswordDto) => {
          updatePassword(data);
          Events.InvestigatorService.InvestigatorChangedPassword({});
        })}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
          }}
        >
          <PasswordField
            {...register('currentPassword', Schemas.password(intl))}
            placeholder={intl.formatMessage({
              id: 'is.account_details.change_password.current_password_input_placeholder',
              defaultMessage: 'Current password',
            })}
            error={errors?.currentPassword?.message}
            data-test-id="account-current-password-input"
          />

          <Button
            variant="tertiary"
            onClick={nextStep}
            data-test-id="account-forgot-password-button"
          >
            <FormattedMessage
              id="is.account_details.change_password.forgot_password_button"
              defaultMessage="Forgotten password?"
            />
          </Button>
        </Flex>

        <Grid
          sx={{
            columnGap: '54px',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <PasswordField
            {...register('newPassword', Schemas.password(intl))}
            placeholder={intl.formatMessage({
              id: 'is.account_details.change_password.new_password_input_placeholder',
              defaultMessage: 'New password',
            })}
            error={errors?.newPassword?.message}
            data-test-id="account-new-password-input"
          />

          <PasswordField
            {...register('confirmNewPassword', {
              validate: (value: string) =>
                value === getValues('newPassword') ||
                intl.formatMessage({
                  id: 'is.account_details.change_password.confirm_new_password.password_no_match_validation_message',
                  defaultMessage: "Your passwords don't match",
                }),
            })}
            placeholder={intl.formatMessage({
              id: 'is.account_details.change_password.confirm_new_password_input_placeholder',
              defaultMessage: 'Confirm new password',
            })}
            error={errors?.confirmNewPassword?.message}
            data-test-id="account-confirm-new-password-input"
          />
        </Grid>

        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="secondary"
            onClick={prevStep}
            data-test-id="account-cancel-password-input"
          >
            <FormattedMessage
              id="is.account_details.change_password.cancel_button"
              defaultMessage="Cancel"
            />
          </Button>

          <Button
            type="submit"
            loading={isLoading}
            data-test-id="account-save-password-input"
          >
            <FormattedMessage
              id="is.account_details.change_password.save_button"
              defaultMessage="Save"
            />
          </Button>
        </Flex>
      </AccountPageLayout.Form>
    </AccountPageLayout>
  );
};
