import React from 'react';
import { AccountPageLayout } from 'pages/Account/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { useMultiStep } from 'components/MultiStep';
import { Schemas, useFormValidation } from 'utils/validation';
import { Services } from 'services';
import { TextField } from '@clariness/clarikit-react';
import { Events } from '@clariness/tracking-event-runtime';

export const AccountDetailsForgotPassword = () => {
  const { prevStep, gotoStep } = useMultiStep();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useFormValidation();

  const intl = useIntl();

  const { sendForgotPasswordEmail, isLoading } =
    Services.SiteEmployees.useSendForgotPasswordEmail({
      onSuccess: () => gotoStep(1),
    });

  const { onChange, name } = register('email');

  return (
    <AccountPageLayout>
      <AccountPageLayout.Heading>
        <FormattedMessage
          id="is.account_details.forgot_password.heading"
          defaultMessage="Forgotten password"
        />
      </AccountPageLayout.Heading>

      <AccountPageLayout.Paragraph>
        <FormattedMessage
          id="is.account_details.forgot_password.main_paragraph"
          defaultMessage="Please confirm your email address associated with this account, and will send you a link to reset your password."
        />
      </AccountPageLayout.Paragraph>

      <AccountPageLayout.Form
        onSubmit={handleSubmit(({ email }) => {
          sendForgotPasswordEmail(email);
          Events.InvestigatorService.InvestigatorForgotPassword({});
        })}
      >
        <TextField
          {...register('email', Schemas.email(intl))}
          placeholder={intl.formatMessage({
            id: 'is.account_details.forgot_password.email_input_placeholder',
            defaultMessage: 'Email address',
          })}
          value={watch('email')}
          onChange={(value) => {
            onChange({ target: { name, value } });
          }}
          error={errors?.email?.message}
          data-test-id="account-forgot-password-email-input"
          autoComplete="on"
          label="email"
          labelHidden
        />

        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="secondary"
            onClick={prevStep}
            data-test-id="account-forgot-password-cancel-button"
          >
            <FormattedMessage
              id="is.account_details.forgot_password.cancel_button"
              defaultMessage="Cancel"
            />
          </Button>

          <Button
            type="submit"
            loading={isLoading}
            data-test-id="account-forgot-password-submit-button"
          >
            <FormattedMessage
              id="is.account_details.forgot_password.send_button"
              defaultMessage="Send link to email"
            />
          </Button>
        </Flex>
      </AccountPageLayout.Form>
    </AccountPageLayout>
  );
};
