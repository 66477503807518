import React, { useState } from 'react';
import { MultiStep } from 'components/MultiStep';
import { generateContext } from 'utils/context';
import { Services } from 'services';
import { BoxLoader } from 'components/loaders';
import { useLocation } from 'react-router-dom';
import { Flex } from '@clariness/flex';
import { Box } from '@clariness/box';
import {
  PatientDetailsScreener,
  PatientDetailsFooter,
  PatientDetailsNotes,
  PatientDetailsProfile,
  PatientDetailsTabMenu,
  PatientDetailsActivity,
} from './components';
import { PatientDetailsContext, PatientDetailsModalProps } from './types';

export const [usePatientDetailsContext, PatientDetailsProvider] =
  generateContext<PatientDetailsContext>();

export const ReferralDetails: React.FC<PatientDetailsModalProps> = () => {
  const location = useLocation();
  const { referralId } = (location.state as any).data;
  const [isEditingNotes, setIsEditingNotes] = useState(false);

  const { referral, isLoading } = Services.Referrals.useGetReferral(referralId);

  return (
    <PatientDetailsProvider
      value={{
        currentReferral: referral,
        isEditingNotes,
        setIsEditingNotes,
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
          py: '64px',
          px: '110px',
          marginBottom: '8px',
          height: '100%',
        }}
      >
        <Flex flexDirection="column">
          {isLoading ? (
            <BoxLoader />
          ) : (
            <MultiStep>
              <PatientDetailsTabMenu
                patientName={`${referral?.patient.firstName} ${referral?.patient.lastName}`}
                patientID={referral?.patient.internalId}
                siteID={referral?.site?.id}
                studyID={referral?.study?.id}
              />
              <MultiStep.Step>
                <PatientDetailsProfile />
              </MultiStep.Step>

              <MultiStep.Step>
                <PatientDetailsScreener referralId={referral?.id} />
              </MultiStep.Step>

              <MultiStep.Step>
                <Box>
                  <Box
                    sx={{
                      height: '48vh',
                      overflow: 'scroll',
                      marginBottom: '5px',
                    }}
                  >
                    <PatientDetailsActivity />
                  </Box>
                  <PatientDetailsNotes />
                </Box>
              </MultiStep.Step>
            </MultiStep>
          )}
        </Flex>
        <PatientDetailsFooter />
      </Flex>
    </PatientDetailsProvider>
  );
};
