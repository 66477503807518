/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StudySiteUpdateDto = {
    id?: number;
    siteId: string;
    studyId: string;
    status: StudySiteUpdateDto.status;
    /**
     * Optimistic concurrency check
     */
    ocVersion?: number;
    protocolId?: string;
    siteCode?: string;
    languageCode?: string;
    metaCity?: string;
    isStudyAssignment?: boolean;
    siteDeactivationReason?: StudySiteUpdateDto.siteDeactivationReason;
}

export namespace StudySiteUpdateDto {

    export enum status {
        SETUP = 'SETUP',
        CANCELED = 'CANCELED',
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
        PAUSED = 'PAUSED',
        CLOSEOUT = 'CLOSEOUT',
        COMPLETED = 'COMPLETED',
        REMOVED = 'REMOVED',
    }

    export enum siteDeactivationReason {
        PATIENT_BACKLOG = 'PATIENT_BACKLOG',
        UNABLE_TO_REACH = 'UNABLE_TO_REACH',
        CAPACITY_REACHED = 'CAPACITY_REACHED',
        ON_SITE_REQUEST = 'ON_SITE_REQUEST',
        SPONSOR_REQUEST = 'SPONSOR_REQUEST',
    }


}
