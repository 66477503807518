import React from 'react';
import { Services } from 'services';
import { ShouldRender } from 'components/ShouldRender';
import { BoxLoader } from 'components/loaders';
import { Flex } from '@clariness/flex';
import { ServiceError } from 'components/ServiceError';
import { FormattedMessage } from 'react-intl';
import { usePatientDetailsContext } from '../../ReferralDetails';
import {
  PatientDetailsBasic,
  PatientDetailsContact,
  StudyDetails,
} from './components';

export const PatientDetailsProfile = () => {
  const { currentReferral } = usePatientDetailsContext();

  const { referral, isLoading, isError, refetch } =
    Services.Referrals.useGetReferral(currentReferral?.id);
  const { Protocol801Participation, isLoading: isProtocol801Loading } =
    Services.Referrals.useUpdateProtocol801Participation({});

  if (isError) {
    return (
      <ServiceError onRetry={refetch}>
        <FormattedMessage
          id="is.patient_details_modal.generic_error_message"
          defaultMessage="Something went wrong while we tried to display the patient's details."
        />
      </ServiceError>
    );
  }

  return (
    <React.Fragment>
      {referral && (
        <Flex
          sx={{
            overflow: isLoading && 'hidden',
            width: '100%',
            height: 'fit-content',
            gap: '48px',
          }}
        >
          <Flex
            sx={{
              width: '50%',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <PatientDetailsBasic referral={referral} />
            <PatientDetailsContact referral={referral} />
          </Flex>

          <Flex sx={{ width: '50%' }}>
            <StudyDetails
              referral={referral}
              Protocol801Participation={Protocol801Participation}
            />
          </Flex>
        </Flex>
      )}

      <ShouldRender when={isLoading || isProtocol801Loading}>
        <BoxLoader />
      </ShouldRender>
    </React.Fragment>
  );
};
