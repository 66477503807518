import { Button } from '@clariness/button';
import { DownloadIcon } from '@clariness/icon';
import { FormattedMessage } from 'react-intl';
import { Services } from 'services';
import { Store } from 'store';

export const ReferralTableExportXls = () => {
  const { filterOptions, selectedRows } = Store.useHomePageStore();

  const { downloadXls, isLoading } = Services.Referrals.useDownloadXls();

  const rowIds = selectedRows.map((row: any) => row.original.patientInternalId);

  return (
    <Button
      variant="secondary"
      style={{ marginTop: '30px' }}
      loading={isLoading}
      disabled={filterOptions?.studyIds?.length === 0}
      icon={<DownloadIcon />}
      size="medium"
      onClick={() =>
        downloadXls({
          studyIds: filterOptions?.studyIds || [],
          patientInternalIds: rowIds,
          statuses: filterOptions?.statuses || [],
        })
      }
    >
      <FormattedMessage
        id="is.home_page.referral_table.export_xls_button"
        defaultMessage="Export XLS"
      />
    </Button>
  );
};
