/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CallbackTimeslotDto } from './CallbackTimeslotDto';
import type { InvestigatorDto } from './InvestigatorDto';
import type { LanguageDto } from './LanguageDto';
import type { PatientDto } from './PatientDto';
import type { PatientReScreenerDto } from './PatientReScreenerDto';
import type { PatientScreenerDto } from './PatientScreenerDto';
import type { ReScreenerDto } from './ReScreenerDto';
import type { ScreenerDto } from './ScreenerDto';
import type { SiteInfoDto } from './SiteInfoDto';
import type { SiteInfoInvestigatorDto } from './SiteInfoInvestigatorDto';
import type { StudyInfoDto } from './StudyInfoDto';
import type { StudySiteDto } from './StudySiteDto';

export type ReferralInvestigatorDto = {
    id?: string;
    status: ReferralInvestigatorDto.status;
    study: StudyInfoDto;
    /**
     * Optimistic concurrency check
     */
    ocVersion?: number;
    consentText: string;
    note?: string;
    domain: string;
    language: LanguageDto;
    screener: ScreenerDto;
    patientScreener: PatientScreenerDto;
    offlineScreener: PatientScreenerDto;
    patient: PatientDto;
    referralDate: string;
    registrationDate: string;
    consentDateTime: string;
    appointmentDateTime?: string;
    postalCode?: string;
    locationBasedOnPostalCode?: string;
    distanceToPatientInKM?: number;
    ageAtTimeOfRegister?: number;
    gender?: ReferralInvestigatorDto.gender;
    /**
     * Optimistic concurrency check
     */
    sourceVersion?: number;
    /**
     * Device ID from Posthog
     */
    deviceId?: string;
    site?: SiteInfoDto;
    siteEmployees: Array<SiteInfoInvestigatorDto>;
    investigators: Array<InvestigatorDto>;
    callbackTime: CallbackTimeslotDto;
    siteCallbackTime: CallbackTimeslotDto;
    warmTransferAllowed?: boolean;
    siterxOptin?: boolean;
    contactDetailsSharingAllowed?: boolean;
    lockDate: string;
    lockedBy: string;
    consentedDate: string;
    isVisited: boolean;
    lastStatusUpdateDate: string;
    reScreener: ReScreenerDto;
    patientReScreener: PatientReScreenerDto;
    availability?: string;
    capacity?: number;
    nac?: number;
    studySites: Array<StudySiteDto>;
    recontactMark?: boolean;
    siterxStatus?: ReferralInvestigatorDto.siterxStatus;
    contactedBySite?: string;
}

export namespace ReferralInvestigatorDto {

    export enum status {
        NEW_REFERRAL = 'NEW_REFERRAL',
        ATTEMPTING_TO_CONTACT = 'ATTEMPTING_TO_CONTACT',
        CONTACTED = 'CONTACTED',
        CONTACTED__MEDICAL_RECORDS = 'CONTACTED__MEDICAL_RECORDS',
        CONTACTED__WASHOUT_PERIOD = 'CONTACTED__WASHOUT_PERIOD',
        CONTACTED__PATIENT_AVAILABILITY = 'CONTACTED__PATIENT_AVAILABILITY',
        CONTACTED__CALLBACK = 'CONTACTED__CALLBACK',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        WAITING_FOR_INFORMATION = 'WAITING_FOR_INFORMATION',
        WAITING_FOR_INFORMATION__MEDICAL_RECORD = 'WAITING_FOR_INFORMATION__MEDICAL_RECORD',
        WAITING_FOR_INFORMATION__WASHOUT_PERIOD = 'WAITING_FOR_INFORMATION__WASHOUT_PERIOD',
        WAITING_FOR_INFORMATION__WAITING_FOR_ICF = 'WAITING_FOR_INFORMATION__WAITING_FOR_ICF',
        CONSENTED = 'CONSENTED',
        RANDOMIZED = 'RANDOMIZED',
        SCREENING_FAILURE = 'SCREENING_FAILURE',
        DROPPED = 'DROPPED',
        DROPPED__UNABLE_TO_REACH = 'DROPPED__UNABLE_TO_REACH',
        DROPPED__FAILED_IN_EXCLUSION_CRITERIA = 'DROPPED__FAILED_IN_EXCLUSION_CRITERIA',
        DROPPED__NOT_INTERESTED = 'DROPPED__NOT_INTERESTED',
        DROPPED__BAD_OR_DUPLICATE_PROFILE = 'DROPPED__BAD_OR_DUPLICATE_PROFILE',
        DROPPED__TOO_FAR_AWAY = 'DROPPED__TOO_FAR_AWAY',
        DROPPED__OTHER = 'DROPPED__OTHER',
        DROPPED__NO_SHOW = 'DROPPED__NO_SHOW',
        IN_CALL_CENTER = 'IN_CALL_CENTER',
        ON_HOLD_IN_CALL_CENTER = 'ON_HOLD_IN_CALL_CENTER',
        DROPPED_BY_CC = 'DROPPED_BY_CC',
        DROPPED_BY_CC__UNABLE_TO_REACH = 'DROPPED_BY_CC__UNABLE_TO_REACH',
        DROPPED_BY_CC__FAILED_IN_EXCLUSION_CRITERIA = 'DROPPED_BY_CC__FAILED_IN_EXCLUSION_CRITERIA',
        DROPPED_BY_CC__NOT_INTERESTED = 'DROPPED_BY_CC__NOT_INTERESTED',
        DROPPED_BY_CC__BAD_OR_DUPLICATE_PROFILE = 'DROPPED_BY_CC__BAD_OR_DUPLICATE_PROFILE',
        DROPPED_BY_CC__TOO_FAR_AWAY = 'DROPPED_BY_CC__TOO_FAR_AWAY',
        DROPPED_BY_CC__DUE_TO_THE_END_OF_THE_ENROLLMENT = 'DROPPED_BY_CC__DUE_TO_THE_END_OF_THE_ENROLLMENT',
        DROPPED_BY_CC__OTHER = 'DROPPED_BY_CC__OTHER',
        RELOCATION = 'RELOCATION',
    }

    export enum gender {
        M = 'M',
        F = 'F',
        U = 'U',
    }

    export enum siterxStatus {
        PRE_CONSENT = 'Pre-Consent',
        NEW = 'New',
        MRR_SENT = 'MRR Sent',
        MRR_SIGNED = 'MRR Signed',
        DID_NOT_SIGN = 'Did Not Sign',
        PHYSICIAN_NOT_VALID = 'Physician Not Valid',
        PHYSICIAN_VALIDATED = 'Physician Validated',
        RECORDS_REQUESTED = 'Records Requested',
        PHYSICIAN_CONTACTED = 'Physician Contacted',
        RECORDS_NOT_RECEIVED = 'Records Not Received',
        RECORDS_RECEIVED = 'Records Received',
        READY_FOR_ELIGIBILITY_REVIEW = 'Ready for Eligibility Review',
        ELIGIBILITY_REVIEW = 'Eligibility Review',
        NOT_QUALIFIED = 'Not Qualified',
        DROPPED = 'Dropped',
        READY_FOR_STUDY = 'Ready for Study',
    }


}
